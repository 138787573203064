import React from 'react'
import styles from './AllDays.module.scss'
import {useTypedSelector} from "../../../hooks/useTypedSelector"
import CalendarPrevMonthDays from '../CalendarPrevMonthDays/CalendarPrevMonthDays'
import CalendarDaySpace from '../CalendarDaySpace/CalendarDaySpace'
import CalendarSingleDay from '../CalendarSingleDay/CalendarSingleDay'

const AllDays = ({bookedDatesArr, leftBorder, rightBorder}) => {

    const {chosenMonthFullDate } =  useTypedSelector(state => state.calendar);

    const parsedDate = new Date(JSON.parse(chosenMonthFullDate));
    const lastDay = new Date(parsedDate.getFullYear(), parsedDate.getMonth() + 1, 0).getDate(); //последний день текущего месяца (определяем сколько дней в текущем месяце)
    const firstDayIndex = parsedDate.getDay() !== 0 ? parsedDate.getDay() : 7; //индекс первого дня текущего месяца (определяем на какой день недели приходится первое число месяца)
    const prevLastDay = new Date(parsedDate.getFullYear(), parsedDate.getMonth(), 0).getDate();
    let arrDays = [];

    for(let i=firstDayIndex-1;i>0;i--){ //построение дат предыдущего месяца
        arrDays.push(
            <CalendarPrevMonthDays
                key={`prev-month-days-wrapper-${i}`}
                i={i}
                prevLastDay={prevLastDay}
            />
        )
    }

    for(let i=1;i<=lastDay;i++){
        arrDays.push(
            <CalendarSingleDay
                key={`day-number-wrapper-key-${i}`}
                i={i}
                bookedDatesArr={bookedDatesArr}
                leftBorder={leftBorder}
                rightBorder={rightBorder}
                firstDayIndex={firstDayIndex}
            />
        )
        
        if((i+firstDayIndex-1)%7!==0){/// пробелы между числами
            arrDays.push(
                <CalendarDaySpace
                    key={`day-number-space-key${i}`}
                    i={i}
                    leftBorder={leftBorder}
                    rightBorder={rightBorder}
                />
            )
        }
    }

    return (
        <div className={styles.box}>
            {arrDays}
        </div>
    );
};

export default AllDays