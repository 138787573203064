import React from 'react'
import styles from './DetailMap.module.scss'
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps'
import nebo from '../../image/DetailMap/nebo.png'

const DetailMap = () => {


    return (
        <div className={styles.main}>
            <div className={styles.box_map}>
                    <YMaps>
                        <Map width='100%' height='100%' defaultState={{ center: [44.410745, 33.720007], zoom: 17}}>
                            <Placemark 
                                geometry={[44.410745, 33.720007]} 
                                    options={{
                                        iconLayout: 'default#image',
                                        iconImageSize: [30, 47],
                                        iconImageHref: nebo,
                                        }}
                                    />
                        </Map>
                    </YMaps>
            </div>

        </div>
    )
}

export default DetailMap