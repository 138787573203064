import React, {useCallback} from 'react'
import styles from './ApartmentButtons.module.scss'
import place from '../../image/Apartments/plo.svg'
import person from '../../image/Apartments/person.svg'
import icon from '../../image/Apartments/icon.svg'
import video from '../../image/Apartments/video.svg'

const ApartmentButtons = ({metre, people, level, isVideoOpen, playVideo}) => {

    const videoClick = useCallback(() => {
        isVideoOpen(playVideo)
    }, [isVideoOpen, playVideo])

    return (
        <div className={styles.box_icon}>
            <div className={styles.item_icon}>
                <img className={styles.icon_play} src={video} alt='стрелочка' onClick={videoClick}/>
                <p className={styles.text_icon}>Видео</p>
            </div>
            <div className={styles.item_icon}>
                <img className={styles.icon} src={place} alt='стрелочка'/>
                <p className={styles.text_icon}>{metre}</p>
            </div>
            <div className={styles.item_icon}>
                <img className={styles.icon} src={person} alt='стрелочка'/>
                <p className={styles.text_icon}>{people}</p>
            </div>
            <div className={styles.item_icon}>
            <img className={styles.icon} src={icon} alt='стрелочка'/>
                <p className={styles.text_icon}>{level}</p>
            </div>
        </div>                                 
    )
}

export default ApartmentButtons