import React from 'react'
import styles from './Booking.module.scss'
import Calendar from '../Calendar/Calendar'
import Callback from '../Callback/Callback'
import {useTypedSelector} from "../../hooks/useTypedSelector"



const Booking = ({room, price, popupOpen}) => {

    const {activeDays, bookingDays, beginning, end,} = useTypedSelector(state => state.calendar);
    const priceRoom = Number(price);
    let priceTotal = 0;
    let numberOfDays = 0;

    if(activeDays){
        priceTotal = (bookingDays - 1) * priceRoom;
    }

    if(activeDays){
        numberOfDays = bookingDays - 1; 
    }

    console.log(beginning)
    console.log(end)
    
    return (
        <div className={styles.booking}>
            <Calendar/>
            <div className={styles.booking_data}>
                <h3 className={styles.subtitle}>Данные бронирования:</h3>
                <p className={styles.room}>{room}</p>
                <p className={styles.price}>Колличество дней: <span>{numberOfDays}</span></p>
                <p className={styles.price}>Стоимость проживания: <span>{priceTotal}</span></p>
            </div>
            <Callback
                room={room}
                beginning={beginning}
                end={end}
                priceTotal={priceTotal}
                numberOfDays={numberOfDays}
                popupOpen={popupOpen}
            />
        </div>
    );
};

export default Booking