import React from 'react'
import styles from './Map.module.scss'
import { motion } from "framer-motion"
import DetailMap from '../DetailMap/DetailMap'
import phone from '../../image/Map/phone.svg'
import email from '../../image/Map/email-icon.svg'
import time from '../../image/Map/time.svg'
import company from '../../image/Map/company.svg'
import vc from '../../image/Map/vk.svg'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}

const cardAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}


const Map = () => {


    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.05, once: true}}
            >
            <motion.div className={styles.box}>
                <div className={styles.info}>
                    <motion.h2 className={styles.title} variants={titleAnimation}>Контакты <span>Laspi&#160;Apartments</span></motion.h2>
                    <motion.div 
                        className={styles.box_contacts}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.icon_box}>
                                <img className={styles.icon_contacts} src={phone} alt = 'icon'/>
                            </div>
                            <a className={styles.link_phone}  href="tel:+78043339499" target='_blank' rel='noopener noreferrer'>+7-804-333-94-99</a>
                    </motion.div>
                    <motion.div 
                        className={styles.box_contacts}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.icon_box}>
                                <img className={styles.icon_contacts} src={phone} alt = 'icon'/>
                            </div>
                            <a className={styles.link_phone}  href="tel:+79886718487" target='_blank' rel='noopener noreferrer'>+7 (988) 671-84-87</a>
                    </motion.div>
                    <motion.div 
                        className={styles.box_contacts}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.icon_box}>
                                <img className={styles.icon_contacts} src={phone} alt = 'icon'/>
                            </div>
                            <a className={styles.link_phone}  href="tel:+79869976569" target='_blank' rel='noopener noreferrer'>+7 (986) 997-65-69</a>
                    </motion.div>
                    <motion.div 
                        className={styles.box_contacts}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.icon_box}>
                                <img className={styles.icon_contacts} src={email} alt = 'icon'/>
                            </div>
                            <a className={styles.link_email}  href="mailto:laspi.apartments@gmail.com" target='_blank' rel='noopener noreferrer'>laspi.apartments@gmail.com</a>
                    </motion.div>
                    <motion.div 
                        className={styles.box_contacts}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.icon_box}>
                                <img className={styles.icon_contacts} src={vc} alt = 'icon'/>
                            </div>
                            <a className={styles.link_email}  href="https://vk.com/laspiapartments" target='_blank' rel='noopener noreferrer'>https://vk.com/laspiapartments</a>
                    </motion.div>
                    <motion.div 
                        className={styles.box_contacts}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.icon_box}>
                                <img className={styles.icon_contacts} src={company} alt = 'icon'/>
                            </div>
                            <p className={styles.text}>Севастопольская зона ЮБК, 14В</p>
                    </motion.div>
                    <motion.div 
                        className={styles.box_contacts_social}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.icon_box}>
                                <img className={styles.icon_contacts} src={time} alt = 'icon'/>
                            </div>
                            <p className={styles.text}>Пн-Пт 00:00 - 24:00 Сб&#8205;-&#8205;Вс&#160;00:00&#160;-&#160;24:00</p>
                    </motion.div>
                    <p className={styles.text}>Заселение в апартаменты происходит с 14-00</p>
                    <div className={styles.box_link}>
                        <a className={styles.link} href="https://yandex.ru/maps/959/sevastopol/house/sevastopolskaya_zona_yubk_14vs1/Z0oYcQVgSUUAQFpufXhwcXtnYQ==/?ll=33.720007%2C44.410745&utm_source=main_stripe_big&z=17" target='_blank' rel='noopener noreferrer'>Проложить маршрут</a>
                        <a className={styles.link} href="mailto:laspi.apartments@gmail.com" target='_blank' rel='noopener noreferrer'>Написать нам</a>
                    </div>
                </div>
                <motion.div 
                    className={styles.box_map}
                    variants={titleAnimation} 
                    initial='hidden'
                    whileInView='visible'
                    viewport={{amount: 0.02, once: true}}
                    >
                    <DetailMap/>
                </motion.div>
                <div className={styles.box_link_min}>
                    <a className={styles.link} href="https://yandex.ru/maps/959/sevastopol/house/sevastopolskaya_zona_yubk_14vs1/Z0oYcQVgSUUAQFpufXhwcXtnYQ==/?ll=33.720007%2C44.410745&utm_source=main_stripe_big&z=17" target='_blank' rel='noopener noreferrer'>Проложить маршрут</a>
                    <a className={styles.link} href="mailto:laspi.apartments@gmail.com" target='_blank' rel='noopener noreferrer'>Написать нам</a>
                </div>
            </motion.div>
        </motion.section>
    )
}

export default Map