import React from 'react';
import styles from './CalendarTop.module.scss'
import { arrMonths } from '../../../utils/calendar';
import CalendarArrowItem from '../CalendarArrowItem/CalendarArrowItem';
import {useTypedSelector} from "../../../hooks/useTypedSelector";


const CalendarTop = () => {
    const {chosenMonth, chosenYear} = useTypedSelector(state => state.calendar);
    
    return (
        <div className={styles.box_data}>
                <CalendarArrowItem 
                    argument={"dec"} 
                    arrowClass={styles.button_left}
                    />
                <div className={styles.box_text}>
                    <p className={styles.title}>{arrMonths[chosenMonth]}</p>
                    <p className={styles.text}>{chosenYear}</p>
                </div>
                <CalendarArrowItem 
                    argument={"inc"} 
                    arrowClass={styles.button_right}
                />
        </div>
    );
};

export default CalendarTop;