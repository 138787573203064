import React from 'react'
import styles from './CarouselPhoto.module.scss'
import { v4 as uuidv4 } from 'uuid'
import { Swiper, SwiperSlide } from 'swiper/react'
import {  Pagination, Navigation} from "swiper"
import 'swiper/scss'
import "swiper/scss/pagination"
import "swiper/scss/navigation"
import "./CarouselPhoto.css"


const CarouselPhoto = ({arr}) => {

        return (
            
                <Swiper
                    style={{
                        '--swiper-navigation-color': '#fff',
                        '--swiper-pagination-color': '#fff',
                        '--swiper-pagination-bottom': '20px',
                    }}
                    spaceBetween={0}
                    slidesPerView={1}
                    centeredSlides={true}
                    navigation={true}
                    loop={true}
                    pagination={{
                        type: 'fraction'
                    }}
                    modules={[Pagination, Navigation]}
                        className="mySwiper"
                    >
                        {arr.map((item, index) => {
                        const keyUid = uuidv4()
                            return(
                                <SwiperSlide
                                    key={keyUid}
                                >
                                    <img className={styles.image} src={item.image} alt = 'логотип'/>
                                </SwiperSlide>
                            ) 
                    })}     
                        </Swiper>
        )
    }
    
    export default CarouselPhoto