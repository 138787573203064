    import React, {useEffect, useCallback} from 'react'
    import styles from '../TwentySeven/TwentySeven.module.scss'
    import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
    import CarouselPhoto from '../../components/CarouselPhoto/CarouselPhoto'
    import { arrNineTeen } from '../../utils/data'
    import { arrApartments } from '../../utils/data'
    import ServiceIcons from '../../components/ServiceIcons/ServiceIcons'
    import ApartmentButtons from '../../components/ApartmentButtons/ApartmentButtons'
    import Booking from '../../components/Booking/Booking'
    
    const NineTeen = ({isImageOpen, isVideoOpen, popupOpen}) => {
    
        useEffect(() => {
            window.scrollTo(0, 0)
        }, [])
    
        const doubleClick = useCallback(() => {
            isImageOpen(arrNineTeen)
        }, [isImageOpen])
    
            return (
                    <section 
                    className={styles.main} 
                    >
                    <div className={styles.container}>
                        <BreadCrumbs/>
                        <div className={styles.room}>
                            <div className={styles.list_image}>
                                <CarouselPhoto
                                    arr={arrNineTeen}
                                />
                            </div>
                            <div className={styles.room_info}>
                                <div>
                                    <h3 className={styles.room_title}>Апартамент 19/6</h3>
                                    <p className={styles.room_price}>{arrApartments[1].number_price} ₽</p>
                                </div>
                                <div className={styles.room_line}></div>
                                <ApartmentButtons
                                    metre={arrApartments[1].metre}
                                    people={arrApartments[1].people}
                                    level={arrApartments[1].level}
                                    isVideoOpen={isVideoOpen}
                                    playVideo={'https://www.youtube.com/embed/xZOEp-kPus0'}
                                />
                                <div className={styles.room_line}></div>
                                <ServiceIcons/>
                            </div>
                        </div>
                        <Booking
                            room={arrApartments[1].name}
                            price={arrApartments[1].number_price}
                            popupOpen={popupOpen}
                        />
                    </div>    
                    </section>
                
            )
        }
        
        export default NineTeen   