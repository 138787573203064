import img_1 from '../image/Apartments/img_1.webp'
import img_2 from '../image/Apartments/img_2.webp'
import img_3 from '../image/Apartments/img_3.webp'
import img_4 from '../image/Apartments/img_4.webp'
import img_5 from '../image/Apartments/img_5.webp'
import img_6 from '../image/Apartments/img_6.webp'
import img_7 from '../image/Apartments/img_7.webp'
import img_8 from '../image/Apartments/img_8.webp'
import img_9 from '../image/Apartments/img_9.webp'

import ts_1 from '../image/Rooms/TwentySeven/img_1.webp'
import ts_2 from '../image/Rooms/TwentySeven/img_2.webp'
import ts_3 from '../image/Rooms/TwentySeven/img_3.webp'
import ts_4 from '../image/Rooms/TwentySeven/img_4.webp'
import ts_5 from '../image/Rooms/TwentySeven/img_5.webp'
import ts_6 from '../image/Rooms/TwentySeven/img_6.webp'
import ts_7 from '../image/Rooms/TwentySeven/img_7.webp'
import ts_8 from '../image/Rooms/TwentySeven/img_8.webp'
import ts_9 from '../image/Rooms/TwentySeven/img_9.webp'
import ts_10 from '../image/Rooms/TwentySeven/img_10.webp'
import ts_11 from '../image/Rooms/TwentySeven/img_11.webp'
import ts_12 from '../image/Rooms/TwentySeven/img_12.webp'
import ts_13 from '../image/Rooms/TwentySeven/img_13.webp'

import nt_1 from '../image/Rooms/NineTeen/img_1.webp'
import nt_2 from '../image/Rooms/NineTeen/img_2.webp'
import nt_3 from '../image/Rooms/NineTeen/img_3.webp'
import nt_4 from '../image/Rooms/NineTeen/img_4.webp'
import nt_5 from '../image/Rooms/NineTeen/img_5.webp'
import nt_6 from '../image/Rooms/NineTeen/img_6.webp'
import nt_7 from '../image/Rooms/NineTeen/img_7.webp'
import nt_8 from '../image/Rooms/NineTeen/img_8.webp'
import nt_9 from '../image/Rooms/NineTeen/img_9.webp'
import nt_10 from '../image/Rooms/NineTeen/img_10.webp'
import nt_11 from '../image/Rooms/NineTeen/img_11.webp'
import nt_12 from '../image/Rooms/NineTeen/img_12.webp'
import nt_13 from '../image/Rooms/NineTeen/img_13.webp'
import nt_14 from '../image/Rooms/NineTeen/img_14.webp'
import nt_15 from '../image/Rooms/NineTeen/img_15.webp'
import nt_16 from '../image/Rooms/NineTeen/img_16.webp'

import ft_1 from '../image/Rooms/FifTeen/img_1.webp'
import ft_2 from '../image/Rooms/FifTeen/img_2.webp'
import ft_3 from '../image/Rooms/FifTeen/img_3.webp'
import ft_4 from '../image/Rooms/FifTeen/img_4.webp'
import ft_5 from '../image/Rooms/FifTeen/img_5.webp'
import ft_6 from '../image/Rooms/FifTeen/img_6.webp'
import ft_7 from '../image/Rooms/FifTeen/img_7.webp'
import ft_8 from '../image/Rooms/FifTeen/img_8.webp'
import ft_9 from '../image/Rooms/FifTeen/img_9.webp'
import ft_10 from '../image/Rooms/FifTeen/img_10.webp'
import ft_11 from '../image/Rooms/FifTeen/img_11.webp'
import ft_12 from '../image/Rooms/FifTeen/img_12.webp'
import ft_13 from '../image/Rooms/FifTeen/img_13.webp'
import ft_14 from '../image/Rooms/FifTeen/img_14.webp'

import tf_1 from '../image/Rooms/ThirtyFour/img_1.webp'
import tf_2 from '../image/Rooms/ThirtyFour/img_2.webp'
import tf_3 from '../image/Rooms/ThirtyFour/img_3.webp'
import tf_4 from '../image/Rooms/ThirtyFour/img_4.webp'
import tf_5 from '../image/Rooms/ThirtyFour/img_5.webp'
import tf_6 from '../image/Rooms/ThirtyFour/img_6.webp'
import tf_7 from '../image/Rooms/ThirtyFour/img_7.webp'
import tf_8 from '../image/Rooms/ThirtyFour/img_8.webp'
import tf_9 from '../image/Rooms/ThirtyFour/img_9.webp'

import f_1 from '../image/Rooms/Fifty/img_1.webp'
import f_2 from '../image/Rooms/Fifty/img_2.webp'
import f_3 from '../image/Rooms/Fifty/img_3.webp'
import f_4 from '../image/Rooms/Fifty/img_4.webp'
import f_5 from '../image/Rooms/Fifty/img_5.webp'
import f_6 from '../image/Rooms/Fifty/img_6.webp'
import f_7 from '../image/Rooms/Fifty/img_7.webp'
import f_8 from '../image/Rooms/Fifty/img_8.webp'
import f_9 from '../image/Rooms/Fifty/img_9.webp'
import f_10 from '../image/Rooms/Fifty/img_10.webp'
import f_11 from '../image/Rooms/Fifty/img_11.webp'
import f_12 from '../image/Rooms/Fifty/img_12.webp'
import f_13 from '../image/Rooms/Fifty/img_13.webp'
import f_14 from '../image/Rooms/Fifty/img_14.webp'
import f_16 from '../image/Rooms/Fifty/img_16.webp'
import f_17 from '../image/Rooms/Fifty/img_17.webp'
import f_18 from '../image/Rooms/Fifty/img_18.webp'
import f_19 from '../image/Rooms/Fifty/img_19.webp'
import f_20 from '../image/Rooms/Fifty/img_20.webp'
import f_21 from '../image/Rooms/Fifty/img_21.webp'

import ff_1 from '../image/Rooms/FortyFour/img_1.webp'
import ff_2 from '../image/Rooms/FortyFour/img_2.webp'
import ff_3 from '../image/Rooms/FortyFour/img_3.webp'
import ff_4 from '../image/Rooms/FortyFour/img_4.webp'
import ff_5 from '../image/Rooms/FortyFour/img_5.webp'
import ff_6 from '../image/Rooms/FortyFour/img_6.webp'
import ff_7 from '../image/Rooms/FortyFour/img_7.webp'
import ff_8 from '../image/Rooms/FortyFour/img_8.webp'
import ff_9 from '../image/Rooms/FortyFour/img_9.webp'
import ff_10 from '../image/Rooms/FortyFour/img_10.webp'
import ff_11 from '../image/Rooms/FortyFour/img_11.webp'
import ff_12 from '../image/Rooms/FortyFour/img_12.webp'
import ff_13 from '../image/Rooms/FortyFour/img_13.webp'
import ff_14 from '../image/Rooms/FortyFour/img_14.webp'
import ff_15 from '../image/Rooms/FortyFour/img_15.webp'
import ff_16 from '../image/Rooms/FortyFour/img_16.webp'
import ff_17 from '../image/Rooms/FortyFour/img_17.webp'
import ff_18 from '../image/Rooms/FortyFour/img_18.webp'
import ff_19 from '../image/Rooms/FortyFour/img_19.webp'

import fe_1 from '../image/Rooms/FortyEight/img_1.webp'
import fe_2 from '../image/Rooms/FortyEight/img_2.webp'
import fe_3 from '../image/Rooms/FortyEight/img_3.webp'
import fe_4 from '../image/Rooms/FortyEight/img_4.webp'
import fe_5 from '../image/Rooms/FortyEight/img_5.webp'
import fe_6 from '../image/Rooms/FortyEight/img_6.webp'
import fe_7 from '../image/Rooms/FortyEight/img_7.webp'
import fe_8 from '../image/Rooms/FortyEight/img_8.webp'
import fe_9 from '../image/Rooms/FortyEight/img_9.webp'
import fe_10 from '../image/Rooms/FortyEight/img_10.webp'
import fe_11 from '../image/Rooms/FortyEight/img_11.webp'
import fe_12 from '../image/Rooms/FortyEight/img_12.webp'
import fe_13 from '../image/Rooms/FortyEight/img_13.webp'
import fe_14 from '../image/Rooms/FortyEight/img_14.webp'
import fe_15 from '../image/Rooms/FortyEight/img_15.webp'
import fe_16 from '../image/Rooms/FortyEight/img_16.webp'
import fe_17 from '../image/Rooms/FortyEight/img_17.webp'
import fe_18 from '../image/Rooms/FortyEight/img_18.webp'
import fe_19 from '../image/Rooms/FortyEight/img_19.webp'
import fe_20 from '../image/Rooms/FortyEight/img_20.webp'
import fe_21 from '../image/Rooms/FortyEight/img_21.webp'
import fe_22 from '../image/Rooms/FortyEight/img_22.webp'
import fe_23 from '../image/Rooms/FortyEight/img_23.webp'
import fe_24 from '../image/Rooms/FortyEight/img_24.webp'

import th_1 from '../image/Rooms/Thirteen/img_1.webp'
import th_2 from '../image/Rooms/Thirteen/img_2.webp'
import th_3 from '../image/Rooms/Thirteen/img_3.webp'
import th_4 from '../image/Rooms/Thirteen/img_4.webp'
import th_5 from '../image/Rooms/Thirteen/img_5.webp'
import th_6 from '../image/Rooms/Thirteen/img_6.webp'
import th_7 from '../image/Rooms/Thirteen/img_7.webp'
import th_8 from '../image/Rooms/Thirteen/img_8.webp'
import th_9 from '../image/Rooms/Thirteen/img_9.webp'
import th_10 from '../image/Rooms/Thirteen/img_10.webp'
import th_11 from '../image/Rooms/Thirteen/img_11.webp'
import th_12 from '../image/Rooms/Thirteen/img_12.webp'
import th_13 from '../image/Rooms/Thirteen/img_13.webp'
import th_14 from '../image/Rooms/Thirteen/img_14.webp'
import th_15 from '../image/Rooms/Thirteen/img_15.webp'
import th_16 from '../image/Rooms/Thirteen/img_16.webp'
import th_17 from '../image/Rooms/Thirteen/img_17.webp'
import th_18 from '../image/Rooms/Thirteen/img_18.webp'
import th_19 from '../image/Rooms/Thirteen/img_19.webp'
import th_20 from '../image/Rooms/Thirteen/img_20.webp'
import th_21 from '../image/Rooms/Thirteen/img_21.webp'

import tw_1 from '../image/Rooms/Twenty/img_1.webp'
import tw_2 from '../image/Rooms/Twenty/img_2.webp'
import tw_3 from '../image/Rooms/Twenty/img_3.webp'
import tw_4 from '../image/Rooms/Twenty/img_4.webp'
import tw_5 from '../image/Rooms/Twenty/img_5.webp'
import tw_6 from '../image/Rooms/Twenty/img_6.webp'
import tw_7 from '../image/Rooms/Twenty/img_7.webp'
import tw_8 from '../image/Rooms/Twenty/img_8.webp'
import tw_9 from '../image/Rooms/Twenty/img_9.webp'
import tw_10 from '../image/Rooms/Twenty/img_10.webp'
import tw_11 from '../image/Rooms/Twenty/img_11.webp'
import tw_12 from '../image/Rooms/Twenty/img_12.webp'
import tw_13 from '../image/Rooms/Twenty/img_13.webp'
import tw_14 from '../image/Rooms/Twenty/img_14.webp'
import tw_15 from '../image/Rooms/Twenty/img_15.webp'
import tw_16 from '../image/Rooms/Twenty/img_16.webp'
import tw_18 from '../image/Rooms/Twenty/img_18.webp'
import tw_19 from '../image/Rooms/Twenty/img_19.webp'
import tw_20 from '../image/Rooms/Twenty/img_20.webp'
import tw_21 from '../image/Rooms/Twenty/img_21.webp'
import tw_22 from '../image/Rooms/Twenty/img_22.webp'
import tw_23 from '../image/Rooms/Twenty/img_23.webp'

export const arrApartments = [{link: '/apartment-27-6', name: 'Апартамент 27/6', metre: '41.8 кв. м.', people: 'до 4 гостей', level: '5 этаж', price: '9 000', number_price: '9000', image: img_1}, {link: '/apartment-19-6', name: 'Апартамент 19/6', metre: '41.8 кв. м.', people: 'до 4 гостей', level: '4 этаж', price: '9 000', number_price: '9000', image: img_2}, {link: '/apartment-15-5', name: 'Апартамент 15/5', metre: '41.8 кв. м.', people: 'до 4 гостей', level: '3 этаж', price: '9 000', number_price: '9000', image: img_3}, {link: '/apartment-34-5', name: 'Апартамент 34/5', metre: '41.8 кв. м.', people: 'до 4 гостей', level: '6 этаж', price: '8 500', number_price: '8500', image: img_4}, {link: '/apartment-50-6', name: 'Апартамент 50/6', metre: '110 кв. м.', people: 'до 6 гостей', level: '9 этаж', price: '15 000', number_price: '15000',image: img_5}, {link: '/apartment-44-5', name: 'Апартамент 44/5', metre: '42 кв. м.', people: 'до 4 гостей', level: '8 этаж', price: '9 000', number_price: '9000', image: img_6}, {link: '/apartment-48-6',name: 'Апартамент 48/6', metre: '60 кв. м.', people: 'до 5 гостей', level: '9 этаж', price: '12 000', number_price: '12000', image: img_7}, {link: '/apartment-13-6',name: 'Апартамент 13/6', metre: '42 кв. м.', people: 'до 4 гостей', level: '3 этаж', price: '9 000', number_price: '9000', image: img_8}, {link: '/apartment-20-6',name: 'Апартамент 20/6', metre: '42 кв. м.', people: 'до 4 гостей', level: '4 этаж', price: '8 000', number_price: '8000', image: img_9},]

export const arrTwentySeven = [{image: ts_3, title: 'Апартамент 27-6'}, {image: ts_1, title: 'Апартамент 27-6'}, {image: ts_2, title: 'Апартамент 27-6'},  {image: ts_4, title: 'Апартамент 27-6'}, {image: ts_5, title: 'Апартамент 27-6'}, {image: ts_6, title: 'Апартамент 27-6'}, {image: ts_7, title: 'Апартамент 27-6'}, {image: ts_8, title: 'Апартамент 27-6'}, {image: ts_9, title: 'Апартамент 27-6'}, {image: ts_10, title: 'Апартамент 27-6'}, {image: ts_11, title: 'Апартамент 27-6'}, {image: ts_12, title: 'Апартамент 27-6'}, {image: ts_13, title: 'Апартамент 27-6'},];

export const arrNineTeen = [{image: nt_2, title: 'Апартамент 19-6'}, {image: nt_1, title: 'Апартамент 19-6'}, {image: nt_3, title: 'Апартамент 19-6'}, {image: nt_4, title: 'Апартамент 19-6'}, {image: nt_5, title: 'Апартамент 19-6'}, {image: nt_6, title: 'Апартамент 19-6'}, {image: nt_7, title: 'Апартамент 19-6'}, {image: nt_8, title: 'Апартамент 19-6'}, {image: nt_9, title: 'Апартамент 19-6'}, {image: nt_10, title: 'Апартамент 19-6'}, {image: nt_11, title: 'Апартамент 19-6'}, {image: nt_12, title: 'Апартамент 19-6'}, {image: nt_13, title: 'Апартамент 19-6'}, {image: nt_14, title: 'Апартамент 19-6'}, {image: nt_15, title: 'Апартамент 19-6'}, {image: nt_16, title: 'Апартамент 19-6'},];

export const arrFifTeen = [{image: ft_1, title: 'Апартамент 15-5'}, {image: ft_2, title: 'Апартамент 15-5'}, {image: ft_3, title: 'Апартамент 15-5'}, {image: ft_4, title: 'Апартамент 15-5'}, {image: ft_5, title: 'Апартамент 15-5'}, {image: ft_6, title: 'Апартамент 15-5'}, {image: ft_7, title: 'Апартамент 15-5'}, {image: ft_8, title: 'Апартамент 15-5'}, {image: ft_9, title: 'Апартамент 15-5'}, {image: ft_10, title: 'Апартамент 15-5'}, {image: ft_11, title: 'Апартамент 15-5'}, {image: ft_12, title: 'Апартамент 15-5'}, {image: ft_13, title: 'Апартамент 15-5'}, {image: ft_14, title: 'Апартамент 15-5'},];

export const arrThirtyFour = [{image: tf_1, title: 'Апартамент 34-5'}, {image: tf_2, title: 'Апартамент 34-5'}, {image: tf_3, title: 'Апартамент 34-5'}, {image: tf_4, title: 'Апартамент 34-5'}, {image: tf_5, title: 'Апартамент 34-5'}, {image: tf_6, title: 'Апартамент 34-5'}, {image: tf_7, title: 'Апартамент 34-5'}, {image: tf_8, title: 'Апартамент 34-5'}, {image: tf_9, title: 'Апартамент 34-5'},]


export const arrFifty = [{image: f_1, title: 'Апартамент 50-6'}, {image: f_2, title: 'Апартамент 50-6'}, {image: f_3, title: 'Апартамент 50-6'}, {image: f_4, title: 'Апартамент 50-6'}, {image: f_5, title: 'Апартамент 50-6'}, {image: f_6, title: 'Апартамент 50-6'}, {image: f_7, title: 'Апартамент 50-6'}, {image: f_8, title: 'Апартамент 50-6'}, {image: f_9, title: 'Апартамент 50-6'}, {image: f_10, title: 'Апартамент 50-6'}, {image: f_11, title: 'Апартамент 50-6'}, {image: f_12, title: 'Апартамент 50-6'}, {image: f_13, title: 'Апартамент 50-6'}, {image: f_14, title: 'Апартамент 50-6'}, {image: f_16, title: 'Апартамент 50-6'}, {image: f_17, title: 'Апартамент 50-6'}, {image: f_18, title: 'Апартамент 50-6'}, {image: f_19, title: 'Апартамент 50-6'}, {image: f_20, title: 'Апартамент 50-6'}, {image: f_21, title: 'Апартамент 50-6'},]

export const arrFortyFour = [{image: ff_2, title: 'Апартамент 44-5'}, {image: ff_1, title: 'Апартамент 44-5'},  {image: ff_3, title: 'Апартамент 44-5'}, {image: ff_4, title: 'Апартамент 44-5'}, {image: ff_5, title: 'Апартамент 44-5'}, {image: ff_6, title: 'Апартамент 44-5'}, {image: ff_7, title: 'Апартамент 44-5'}, {image: ff_8, title: 'Апартамент 44-5'}, {image: ff_8, title: 'Апартамент 44-5'}, {image: ff_9, title: 'Апартамент 44-5'}, {image: ff_10, title: 'Апартамент 44-5'}, {image: ff_11, title: 'Апартамент 44-5'}, {image: ff_12, title: 'Апартамент 44-5'}, {image: ff_13, title: 'Апартамент 44-5'}, {image: ff_14, title: 'Апартамент 44-5'}, {image: ff_15, title: 'Апартамент 44-5'}, {image: ff_16, title: 'Апартамент 44-5'}, {image: ff_17, title: 'Апартамент 44-5'}, {image: ff_18, title: 'Апартамент 44-5'}, {image: ff_19, title: 'Апартамент 44-5'}, ]

export const arrFortyEight = [{image: fe_1, title: 'Апартамент 48-6'}, {image: fe_2, title: 'Апартамент 48-6'}, {image: fe_3, title: 'Апартамент 48-6'}, {image: fe_4, title: 'Апартамент 48-6'}, {image: fe_5, title: 'Апартамент 48-6'}, {image: fe_6, title: 'Апартамент 48-6'},{image: fe_7, title: 'Апартамент 48-6'}, {image: fe_8, title: 'Апартамент 48-6'}, {image: fe_9, title: 'Апартамент 48-6'}, {image: fe_10, title: 'Апартамент 48-6'}, {image: fe_11, title: 'Апартамент 48-6'}, {image: fe_12, title: 'Апартамент 48-6'}, {image: fe_13, title: 'Апартамент 48-6'}, {image: fe_14, title: 'Апартамент 48-6'}, {image: fe_15, title: 'Апартамент 48-6'}, {image: fe_16, title: 'Апартамент 48-6'}, {image: fe_17, title: 'Апартамент 48-6'}, {image: fe_18, title: 'Апартамент 48-6'}, {image: fe_19, title: 'Апартамент 48-6'}, {image: fe_20, title: 'Апартамент 48-6'}, {image: fe_21, title: 'Апартамент 48-6'}, {image: fe_22, title: 'Апартамент 48-6'}, {image: fe_23, title: 'Апартамент 48-6'}, {image: fe_24, title: 'Апартамент 48-6'},]

export const arrThirteen = [{image: th_1, title: 'Апартамент 13-6'}, {image: th_2, title: 'Апартамент 13-6'}, {image: th_3, title: 'Апартамент 13-6'}, {image: th_4, title: 'Апартамент 13-6'}, {image: th_5, title: 'Апартамент 13-6'}, {image: th_6, title: 'Апартамент 13-6'}, {image: th_7, title: 'Апартамент 13-6'}, {image: th_8, title: 'Апартамент 13-6'}, {image: th_9, title: 'Апартамент 13-6'}, {image: th_11, title: 'Апартамент 13-6'}, {image: th_12, title: 'Апартамент 13-6'},  {image: th_14, title: 'Апартамент 13-6'}, {image: th_19, title: 'Апартамент 13-6'}, {image: th_20, title: 'Апартамент 13-6'}, {image: th_21, title: 'Апартамент 13-6'}, {image: th_10, title: 'Апартамент 13-6'}, {image: th_13, title: 'Апартамент 13-6'}, {image: th_15, title: 'Апартамент 13-6'}, {image: th_16, title: 'Апартамент 13-6'}, {image: th_17, title: 'Апартамент 13-6'}, {image: th_18, title: 'Апартамент 13-6'},]

export const arrTwenty = [{image: tw_1, title: 'Апартамент 20-6'}, {image: tw_2, title: 'Апартамент 20-6'}, {image: tw_5, title: 'Апартамент 20-6'}, {image: tw_6, title: 'Апартамент 20-6'}, {image: tw_7, title: 'Апартамент 20-6'}, {image: tw_8, title: 'Апартамент 20-6'}, {image: tw_9, title: 'Апартамент 20-6'}, {image: tw_10, title: 'Апартамент 20-6'}, {image: tw_11, title: 'Апартамент 20-6'}, {image: tw_12, title: 'Апартамент 20-6'}, {image: tw_13, title: 'Апартамент 20-6'},  {image: tw_15, title: 'Апартамент 20-6'}, {image: tw_19, title: 'Апартамент 20-6'}, {image: tw_20, title: 'Апартамент 20-6'}, {image: tw_21, title: 'Апартамент 20-6'}, {image: tw_22, title: 'Апартамент 20-6'}, {image: tw_23, title: 'Апартамент 20-6'},  {image: tw_3, title: 'Апартамент 20-6'}, {image: tw_4, title: 'Апартамент 20-6'}, {image: tw_14, title: 'Апартамент 20-6'}, {image: tw_16, title: 'Апартамент 20-6'}, {image: tw_18, title: 'Апартамент 20-6'},]