import React from 'react';
import styles from './CalendarPrevMonthDays.scss'


const CalendarPrevMonthDays = ({i, prevLastDay}) => {
    return (
        <>
            <div className={`prev-month-days-wrapper`}>
                <div
                    className={`prev-month-days`}>{prevLastDay-i+1}</div>
            </div>
            <div
                className={`day-number-space`}>
            </div>
        </>
    );
};

export default CalendarPrevMonthDays;