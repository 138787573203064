import React, { useCallback, useState, useEffect, useRef } from 'react'
import styles from './Callback.module.scss'
import emailjs from 'emailjs-com'
import { FormValidation } from '../../hooks/FormValidation'
import InputMask from "react-input-mask"


const Callback = ({popupOpen, room, beginning, end, priceTotal, numberOfDays}) => {

    const handleForm = FormValidation()
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const form = useRef()

    useEffect(() => {
        handleForm.isValid ? setButtonDisabled(false) : setButtonDisabled(true)
    }, [handleForm.isValid])

    const sendData = useCallback((evt) => {
        evt.preventDefault();
            emailjs.sendForm('service_zste9oo', 'template_rwg6e44', form.current, 'i2hvo7HObkbNyVteX')
            .then((result) => {
            }, (error) => {
                console.log(error.text);
            }); 
        handleForm.resetForm()
        popupOpen()
    }, [handleForm, popupOpen] )


    return (
        <form className={styles.form} onSubmit={sendData} ref={form}>
                    <div className={styles.box_input}>
                        <label className={styles.label}>Введите имя
                            <input
                                className={`${styles.input} ${handleForm.errors.name && styles.wrong}`}
                                type='text' 
                                name='name'
                                value={handleForm.values.this}
                                onChange={handleForm.handleChange} 
                                pattern='^[A-Za-zА-Яа-яЁё\s\-]{2,30}$'
                                required/>
                        </label>
                    </div>
                    <span className={styles.input_error}>{handleForm.errors.name}</span>
                    <div className={styles.box_input}>
                        <label className={styles.label}>Введите телефон      
                            <InputMask
                                className={`${styles.input} ${handleForm.errors.phone && styles.wrong}`}
                                type='tel' 
                                name='phone'
                                value={handleForm.values.this}
                                onChange={handleForm.handleChange}
                                mask={'+7\\(999) 999-99-99'}  
                                pattern='\+7\s?[\(][0-9]{3}[\)]{0,1}\s?\d{3}[-]{0,1}\d{2}[-]{0,1}\d{2}'
                                required/>
                        </label>
                    </div>
                    <span className={styles.input_error}>{handleForm.errors.phone}</span>
                    <div className={styles.box_input}>
                        <label className={styles.label}>E-mail
                            <input
                                className={`${styles.input} ${handleForm.errors.mail && styles.wrong}`}
                                type='text' 
                                name='mail'
                                value={handleForm.values.this}
                                onChange={handleForm.handleChange} 
                                required/>
                        </label>
                    </div>
                    <span className={styles.input_error}>{handleForm.errors.mail}</span>
                    <div className={styles.box_input}>
                        <label className={styles.label}>Количество человек
                            <input
                                className={`${styles.input} ${handleForm.errors.person && styles.wrong}`}
                                type='text' 
                                name='person'
                                value={handleForm.values.this}
                                onChange={handleForm.handleChange} 
                                required/>
                        </label>
                    </div>
                    <span className={styles.input_error}>{handleForm.errors.person}</span>
                    
                            <input
                                className={styles.input_none}
                                type='text' 
                                name='room'
                                value={room}
                                /> 
                                <input
                                className={styles.input_none}
                                type='text' 
                                name='days'
                                value={numberOfDays}
                                />
                                <input
                                className={styles.input_none}
                                type='text' 
                                name='beginning'
                                value={beginning}
                                />
                                <input
                                className={styles.input_none}
                                type='text' 
                                name='end'
                                value={end}
                                />
                                <input
                                className={styles.input_none}
                                type='text' 
                                name='price'
                                value={priceTotal}
                                />

                                
                    <div className={styles.box_textarea}>
                        <label className={styles.label_textarea}>Комментарий
                            <textarea
                                    className={`${styles.textarea} ${styles.scrollbar}`}
                                    name='comment'
                                    value={handleForm.values.this}
                                    onChange={handleForm.handleChange}/>       
                        </label>
                    </div>                
                        <button className={`${styles.button_form} ${buttonDisabled && styles.button_disabled}`}type='submit'><span>забронировать</span></button>
                </form>
                
    )
}

export default Callback