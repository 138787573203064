import React, {useEffect} from 'react'
import { createPortal } from "react-dom"
import modal from './ModalVideo.module.scss'
import {ModalOverlay} from '../ModalOverlay/ModalOverlay'

const ModalVideo = ({video, onClose}) => {

    const modalRoot = document.getElementById("modals")

    useEffect(() => {
        const handleEscClose = (evt) =>{
            if (evt.key ==='Escape')  onClose()
        }
        document.addEventListener('keydown', handleEscClose)
        return () => document.removeEventListener('keydown', handleEscClose)
    }, [onClose])

    return createPortal (
        <ModalOverlay onClick={onClose}>
            <div className={modal.modal}>
            <button data-test="close-button" className={modal.close_icon} type="button" aria-label="закрыть" onClick={onClose}/>
            <iframe
                className={modal.video}
                src={video}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen>
            </iframe>
        </div>
        </ModalOverlay>
        ,modalRoot
    );
};

export default ModalVideo