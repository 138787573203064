import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    chosenMonthFullDate: JSON.stringify(new Date(new Date().getFullYear(), new Date().getMonth(), 1)),
    chosenMonth: new Date().getMonth(),
    chosenYear: new Date().getFullYear(),
    activeDays: 0,
    bookingDays: 0,
    pricePeriod: 0,
    currentContent: "specific: empty",
    bookedDaysFromServer: "",
    beginning: '',
    end: '',
    bookedInfo: {},
    bookedRange: {
        leftBorder: "",
        rightBorder: ""
    }
}

const calendarSlice = createSlice({
    name: "calendar",
    initialState,
    reducers: {
        setChosenMonth(state, action) {state.chosenMonth = action.payload},
        setChosenYear(state, action) {state.chosenYear = action.payload},
        setChosenDate(state, action) {state.chosenMonthFullDate = action.payload},
        setBookedRange(state, action) {state.bookedRange = action.payload},
        setActiveDays(state, action) {state.activeDays = action.payload},
        setBookingDays(state, action) {state.bookingDays = action.payload},
        setBookedDaysFromServer(state, action) {state.bookedDaysFromServer = action.payload},
        setCurrentContent(state,action) {state.currentContent = action.payload },
        setBookedInfo(state,action) {state.bookedInfo = action.payload },
        setPricePeriod(state, action) {state.pricePeriod = action.payload},
        setBeginning(state, action) {state.beginning = action.payload},
        setEnd(state, action) {state.end = action.payload},
    }
})

const {reducer, actions} = calendarSlice;

export default reducer

export const {setChosenMonth, setChosenYear, setChosenDate, setBookedRange, setActiveDays, setBookedDaysFromServer, setBookingDays, setCurrentContent, setBookedInfo, setPricePeriod, setBeginning, setEnd} = actions