import React from 'react'
import { useLocation } from 'react-router-dom'
import header from './header.module.scss'
import logo from '../../image/Header/logo.svg'
import logo_bl from '../../image/Header/logo_bl.png'
import viber from '../../image/Header/viber.svg'
import telegram from '../../image/Header/telegram.svg'
import whats from '../../image/Header/whats-up.svg'
import instagram from '../../image/Header/instagram.svg'
import instagram_page from '../../image/Header/instagram_page.svg'
import telegram_page from '../../image/Header/telegram_page.svg'
import whats_page from '../../image/Header/whats-up_page.svg'
import viber_page from '../../image/Header/viber_page.svg'

const AppHeader = ({isOpen}) => {

    const routes  = useLocation()

    return (
    <header className={header.header}>
        <div className={header.box}>
        {routes.pathname === '/'  ? (<img className={header.logo} src={logo} alt = 'логотип'/>) :
        (<img className={header.logo_bl} src={logo_bl} alt = 'логотип'/>)}    
        <div className={header.contacts}>
        <ul className={header.list}>
        <li className={header.item}>
                <a className={header.link_icon} target='_blank' rel='noopener noreferrer' href='viber://add?number=79886718487' aria-label='вайбер'>
                    <img className={header.image_icon} src={routes.pathname === '/'  ? viber : viber_page} alt = 'логотип'/>
                </a>
            </li>
            <li className={header.item}>
                <a className={header.link_icon} target='_blank' rel='noopener noreferrer' href='https://t.me/+79886718487' aria-label='телеграмм'>
                    <img className={header.image_icon} src={routes.pathname === '/'  ? telegram : telegram_page} alt = 'логотип'/>
                </a>
            </li>
            <li className={header.item}>
                <a className={header.link_icon} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79886718487' aria-label='связаться вацап'>
                    <img className={header.image_icon} src={routes.pathname === '/'  ? whats : whats_page} alt = 'логотип'/>
                </a>
            </li>
            <li className={header.item}>
                <a className={header.link_icon} target='_blank' rel='noopener noreferrer' href='https://www.instagram.com/laspi.apartments/' aria-label='инстаграмм'>
                <img className={header.image_icon} src={routes.pathname === '/'  ? instagram : instagram_page} alt = 'логотип'/>
                </a>
            </li>
            </ul>
            <a className={`${routes.pathname === '/'  ? header.phone : header.phone_page}`}  href="tel:+78043339499" target='_blank' rel='noopener noreferrer'>+7-804-333-94-99</a>
            <button className={`${routes.pathname === '/'  ? header.button : header.button_page}`} type='button' onClick={isOpen} aria-label='Открыть меню'/>
        </div>    
        </div>
    </header>
    );
};

export default AppHeader