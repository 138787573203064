import React, {useEffect} from 'react'
import {setCurrentContent, setBookingDays, setBeginning, setEnd} from "../../components/Calendar/calendar.slice";
import {useDispatch} from "react-redux";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import styles from './Calendar.module.scss'
import WeekDays from './WeekDays/WeekDays'
import AllDays from './AllDays/AllDays'
import CalendarTop from './CalendarTop/CalendarTop';
import { arrMonthsData } from '../../utils/calendar';


const Calendar = () => {

    const dispatch = useDispatch();

    const {chosenMonth, chosenYear, activeDays, bookingDays} = useTypedSelector(state => state.calendar);
    let bookedDatesArr = [];
    let leftBorder="", rightBorder="";


    const initializeCurrentContent = () => {
        if(bookingDays){
            dispatch(setCurrentContent("specific: filled"));
        } else {
            dispatch(setCurrentContent("specific: empty"));
        }
    }

    const updateBookingDays = () => { //функция для обновления количества забронированных дней
        const years = Object.keys(activeDays)
        let totalDays = 0;
        years.map(year => {
            const months = Object.keys(activeDays[year])
            months.map(month=>{
                if(activeDays[year][month].leftDay && activeDays[year][month].rightDay){
                    totalDays += activeDays[year][month].rightDay-activeDays[year][month].leftDay + 1
                } else if(!activeDays[year][month].leftDay && !activeDays[year][month].rightDay){
                    totalDays += 0
                } else {
                    totalDays += 1
                }
            })
        })
        dispatch(setBookingDays(totalDays))
    };


    const defineActiveDaysBorder = () => {
        if(activeDays[chosenYear]){
            leftBorder = activeDays[chosenYear][chosenMonth] ? activeDays[chosenYear][chosenMonth].leftDay: "";
            rightBorder = activeDays[chosenYear][chosenMonth] ? activeDays[chosenYear][chosenMonth].rightDay: "";
        }
    }

    useEffect(()=>{
        initializeCurrentContent();
        updateBookingDays();
    },[activeDays, bookingDays])

    defineActiveDaysBorder();

    let beginning = leftBorder + ' ' + arrMonthsData[chosenMonth] + ' ' + chosenYear
    let end = rightBorder + ' ' + arrMonthsData[chosenMonth] + ' ' + chosenYear
    
    dispatch(setBeginning(beginning))
    dispatch(setEnd(end))

    return (
        <div className={styles.box}>
            <CalendarTop/>
            <div className={styles.body}>
                <WeekDays/>
                <div className={styles.numbers}>
                    <AllDays
                        leftBorder={leftBorder}
                        rightBorder={rightBorder}
                        bookedDatesArr={bookedDatesArr}
                    />
                </div>
            </div>
        </div>
    )
}

export default Calendar