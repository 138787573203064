import React from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router"
import styles from './BreadCrumbs.module.scss'
import icon from '../../image/BreadCrumbs/back-btn.svg'

const BreadCrumbs = () => {

    const navigate = useNavigate();
    
    const goBack = () => navigate(-1);

    return (
    <section className={styles.main}>
        <div className={styles.box}>
            <Link className={styles.menu_link} to='' onClick={goBack}>
                <img className={styles.icon} src={icon} alt = 'логотип'/>
            </Link>
        </div>
    </section>
    );
};

export default BreadCrumbs