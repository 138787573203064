import React from 'react'
import styles from './Footer.module.scss'
import logo from '../../image/Header/logo.svg'
import ButtonBox from '../ButtonBox/ButtonBox'

const Footer = () => {

    return (
        <footer className={styles.main} id='kontakty'>
            <div className={styles.container}>
                <div className={styles.box}>
                    <img className={styles.logo} src={logo} alt = 'логотип'/>
                    <div className={styles.box_mail}>
                        <a className={styles.link_mail}  href="tel:+78043339499" target='_blank' rel='noopener noreferrer'>+7-804-333-94-99</a>
                        <a className={styles.link_mail}  href="tel:+79886718487" target='_blank' rel='noopener noreferrer'>+7-988-671-84-87</a>
                        <a className={styles.link_mail}  href="tel:+79869976569" target='_blank' rel='noopener noreferrer'>+7-986-997-65-69</a>
                    </div>
                    <ButtonBox/>
                </div>
                <div className={styles.box}>
                    <p className={styles.text}>Обращаем ваше внимание на то, что данный интернет-сайт носит исключительно информационный характер и ни при каких условиях не является публичной офертой, определяемой положениями Статьи 437 Гражданского кодекса Российской Федерации. Копирование материалов сайта запрещено.</p>
                    <p className={styles.text}>&#169; 2024 Все права защищены.</p>
                    <a className={styles.link_developer} target='_blank' rel='noopener noreferrer' href='https://tihonov-studio.ru/' aria-label='сайт тихонова дмитрия'>
                        <p className={styles.text}>Разработано<span> TD</span></p>
                    </a>
                </div>
            </div>
        </footer>
    )
}

export default Footer