import React, {useEffect, useCallback} from 'react'
import styles from './TwentySeven.module.scss'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import CarouselPhoto from '../../components/CarouselPhoto/CarouselPhoto'
import { arrTwentySeven } from '../../utils/data'
import { arrApartments } from '../../utils/data'
import ServiceIcons from '../../components/ServiceIcons/ServiceIcons'
import ApartmentButtons from '../../components/ApartmentButtons/ApartmentButtons'
import Booking from '../../components/Booking/Booking'

const TwentySeven = ({isImageOpen, isVideoOpen, popupOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const doubleClick = useCallback(() => {
        isImageOpen(arrTwentySeven)
    }, [isImageOpen])

        return (
                <section 
                className={styles.main} 
                >
                <div className={styles.container}>
                    <BreadCrumbs/>
                    <div className={styles.room}>
                        <div className={styles.list_image}>
                            <CarouselPhoto
                                arr={arrTwentySeven}
                            />
                        </div>
                        <div className={styles.room_info}>
                            <div>
                                <h3 className={styles.room_title}>Апартамент 27/6</h3>
                                <p className={styles.room_price}>{arrApartments[0].number_price} ₽</p>
                            </div>
                            <div className={styles.room_line}></div>
                            <ApartmentButtons
                                metre={arrApartments[0].metre}
                                people={arrApartments[0].people}
                                level={arrApartments[0].level}
                                isVideoOpen={isVideoOpen}
                                playVideo={'https://www.youtube.com/embed/U1OyIR3CA1E'}
                            />
                            <div className={styles.room_line}></div>
                            <ServiceIcons/>
                        </div>
                    </div>
                    <Booking
                        room={arrApartments[0].name}
                        price={arrApartments[0].number_price}
                        popupOpen={popupOpen}
                    />
                </div>    
                </section>
            
        )
    }
    
    export default TwentySeven