import React from 'react';
import  './CalendarDaySpace.scss'

const CalendarDaySpace = ({i, leftBorder, rightBorder}) => {
    return (
        <div
            className={`day-number-space${
                (i >= +leftBorder && i < +rightBorder) ? " colored_full" : ""
            }`}>
        </div>
    );
};

export default CalendarDaySpace;