import React, {useEffect} from 'react'
import styles from './Contacts.module.scss'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import Map from '../../components/Map/Map'



const Contacts = ({popupOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

        return (
            <>
                <section 
                className={styles.main} 
                >
                <BreadCrumbs
                    text={'Контакты'}
                />
                    <Map/>
                </section>
            </>
            
        )
    }
    
    export default Contacts