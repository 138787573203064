import React from 'react'
import styles from './ButtonBox.module.scss'
import viber from '../../image/Header/viber.svg'
import telegram from '../../image/Header/telegram.svg'
import whats from '../../image/Header/whats-up.svg'
import instagram from '../../image/Header/instagram.svg'

const ButtonBox = () => {


    return (
        <ul className={styles.list}>
        <li className={styles.item}>
                <a className={styles.link_icon} target='_blank' rel='noopener noreferrer' href='viber://add?number=79886718487' aria-label='вайбер'>
                    <img className={styles.image_icon} src={viber} alt = 'логотип'/>
                </a>
            </li>
            <li className={styles.item}>
                <a className={styles.link_icon} target='_blank' rel='noopener noreferrer' href='https://t.me/+79886718487' aria-label='телеграмм'>
                    <img className={styles.image_icon} src={telegram} alt = 'логотип'/>
                </a>
            </li>
            <li className={styles.item}>
                <a className={styles.link_icon} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79886718487' aria-label='связаться вацап'>
                    <img className={styles.image_icon} src={whats} alt = 'логотип'/>
                </a>
            </li>
            <li className={styles.item}>
                <a className={styles.link_icon} target='_blank' rel='noopener noreferrer' href='https://www.instagram.com/laspi.apartments/' aria-label='инстаграмм'>
                <img className={styles.image_icon} src={instagram} alt = 'логотип'/>
                </a>
            </li>
            </ul>
    )
}

export default ButtonBox