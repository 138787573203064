import React, {useEffect} from 'react'
import styles from './AboutUs.module.scss'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import logo from '../../image/Header/log.svg'
import { Swiper, SwiperSlide } from 'swiper/react'
import {  Pagination, Navigation} from "swiper"
import 'swiper/scss'
import "swiper/scss/pagination"
import "swiper/scss/navigation"
import "./Feedback.css"


const AboutUs = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

        return (
                <section 
                className={styles.main} 
                >
                <BreadCrumbs
                    text={'О нас'}
                />
                <Swiper
                    style={{
                        '--swiper-navigation-color': '#fff',
                        '--swiper-pagination-color': '#fff',
                        '--swiper-pagination-bottom': '50px',
                    }}
                    spaceBetween={0}
                    slidesPerView={1}
                    centeredSlides={true}
                    navigation={true}
                    loop={true}
                    pagination={{
                        type: 'fraction'
                    }}
                    modules={[Pagination, Navigation]}
                        className="mySwiper"
                    >
                            <SwiperSlide>
                                <div className={styles.box}>
                                    <div className={styles.overlay}>
                                        <div className={styles.box_info}>
                                            <img className={styles.logo} src={logo} alt = 'логотип'/>
                                            <p className={styles.text}>"Laspi Apartments" - уникальные апартаменты, расположенные на южном берегу Крыма в Ласпинской бухте между Севастополем и Ялтой. Рядом с комплексом находится отель "Бухта Мечты". Территория Laspi Apartments" утопает в можжевеловой роще и окружено кристально чистой водой.</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.box_two}>
                                    <div className={styles.overlay}>
                                        <div className={styles.box_info}>
                                            <img className={styles.logo} src={logo} alt = 'логотип'/>
                                            <p className={styles.text}>Предоставляем только люксовые апартаменты с лучшим видом на море в Крыму </p>
                                            <p className={styles.text}>- Площадь от 42 кв.м </p>
                                            <p className={styles.text}>- Дизайнерский ремонт</p>
                                            <p className={styles.text}>- Лучшие виды на чёрное море </p>
                                            <p className={styles.text}>- Личный просторный балкон </p>
                                            <p className={styles.text}>- Дополнительные удобства (холодильник, фен, телевизор, кондиционер, wi-fi, гардеробная комната, электрический чайник и духовой шкаф, утюг и гладильная доска, сейф, пол с подогревом)</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.box_three}>
                                    <div className={styles.overlay}>
                                        <div className={styles.box_info}>
                                            <img className={styles.logo} src={logo} alt = 'логотип'/>
                                            <p className={styles.text}>На территории Laspi Appartaments расположен бассейн-инфинити с завораживающим видом на море - идеальная локация для фотографий</p>
                                            <p className={styles.text_gap}>Площадь: 300 кв.м.</p>
                                            <p className={styles.text}>Наш бассейн с морской водой разделен на 3 зоны: </p>
                                            <p className={styles.text}>Взрослый бассейн</p>
                                            <p className={styles.text}>Детский бассейн </p>
                                            <p className={styles.text}>Гидромассажная зона </p>
                                            <p className={styles.text_gap}>Дополнительные удобства: Шезлонги, душ</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.box_four}>
                                    <div className={styles.overlay}>
                                        <div className={styles.box_info}>
                                            <img className={styles.logo} src={logo} alt = 'логотип'/>
                                            <p className={styles.text}>Для наших гостей мы предлагаем беседки с мангалом, оборудованные столами, скамейками и всем необходимым для барбекю. </p>
                                            <p className={styles.text}>Проведите время на свежем воздухе не только полезно, но и вкусно!</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.box_five}>
                                    <div className={styles.overlay}>
                                        <div className={styles.box_info}>
                                            <img className={styles.logo} src={logo} alt = 'логотип'/>
                                            <p className={styles.text}>Теннисный корт, детская и спортивная площадка, бассейн и джакузи с подогревом для Вас и Ваших детей</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.box_six}>
                                    <div className={styles.overlay}>
                                        <div className={styles.box_info}>
                                            <img className={styles.logo} src={logo} alt = 'логотип'/>
                                            <p className={styles.text}>Охраняемая закрытая территория с парковкой для Вашего удобства и спокойствия</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.box_seven}>
                                    <div className={styles.overlay}>
                                        <div className={styles.box_info}>
                                            <img className={styles.logo} src={logo} alt = 'логотип'/>
                                            <p className={styles.text}>Аппартаменты окружены можжевеловами лесами, поэтому Ваш отдых будет не только комфортным, но и полезным.</p>
                                            <p className={styles.text}>Целебные свойства можжевельника:</p>
                                            <p className={styles.text}>- Восстанавливает сон</p>
                                            <p className={styles.text}>- Устраняет головную боль</p>
                                            <p className={styles.text}>- Нормализует артериальное давление</p>
                                            <p className={styles.text}>- Расслабляет нервную систему</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.box_eight}>
                                    <div className={styles.overlay}>
                                        <div className={styles.box_info}>
                                            <img className={styles.logo} src={logo} alt = 'логотип'/>
                                            <p className={styles.text}>Пребрежный воздух, наделённый целебными свойствами , тёплое солнце и богатая природа наполнят ощущением силы, подарят заряд энергии и бодрости</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.box_nine}>
                                    <div className={styles.overlay}>
                                        <div className={styles.box_info}>
                                            <img className={styles.logo} src={logo} alt = 'логотип'/>
                                            <p className={styles.text}>По договоренности возможны гибкие условия размещения, в том числе пребывание с животными</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.box_ten}>
                                    <div className={styles.overlay}>
                                        <div className={styles.box_info}>
                                            <img className={styles.logo} src={logo} alt = 'логотип'/>
                                            <p className={styles.text}>По климату ЮБК близок к Лазурному берегу Франции. Субтропический средиземноморский климат предполагает длительный курортный сезон (с мая по октябрь) с минимальным количеством пасмурных дней. Не в сезон на южном побережье, которое защищено Крымскими горами от вторжения холодных масс воздуха и подогревается теплым Крымским течением, температура не падает ниже нуля</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.box_eleven}>
                                    <div className={styles.overlay}>
                                        <div className={styles.box_info}>
                                            <img className={styles.logo} src={logo} alt = 'логотип'/>
                                            <p className={styles.text}>На расстоянии 150-200 метров от наших апартаментов расположены два пляжа. Один из пляжей имеет в своём распоряжении три искусственные благоустроенные пляжные зоны со всеми необходимыми удобствами</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.box_twelve}>
                                    <div className={styles.overlay}>
                                        <div className={styles.box_info}>
                                            <img className={styles.logo} src={logo} alt = 'логотип'/>
                                            <p className={styles.text}>Вблизи Ласпи располагаются множества достопримечательностей, такие как: Храм Солнца, Смотровая площадка на трассе Севастополь-Ялта, Форосская церковь, Байдарская долина, Скельская пещера, Крымский Санторини, замок Ласточкино гнездо,  Массандровский дворец, Никитский ботанический сад и другие</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                </section>
        )
    }
    
    export default AboutUs