import React from 'react';
import  './CalendarSingleDay.module.scss'
import {setActiveDays, setBookedDaysFromServer} from "../../Calendar/calendar.slice";
import {useDispatch} from "react-redux";
import {useTypedSelector} from "../../../hooks/useTypedSelector";

const CalendarSingleDay = ({i, bookedDatesArr, leftBorder, rightBorder, firstDayIndex}) => {

    const dispatch = useDispatch();
    const {chosenYear, chosenMonth} = useTypedSelector(state => state.calendar);
    const {activeDays} = useTypedSelector(state => state.calendar);
    const updateActiveDays = (left, right) => { // функция для изменения состояния "количества забронированных дней"
        dispatch(setActiveDays({...activeDays,
            [chosenYear]: {
                ...activeDays[chosenYear], //разворачиваем уже имеющиеся значения в текущем году
                [chosenMonth]: { // + добавляем даты в новом месяце
                    leftDay: left,
                    rightDay: right
                }
            }
        }))
    }

    return (
        <React.Fragment>
            <div
                onClick={(e)=>{
                    dispatch(setBookedDaysFromServer(bookedDatesArr));
                    e.preventDefault()
                    if(!bookedDatesArr.includes(i)){ //проверяем, нет ли текущего числа в массиве с забронированными числами
                        if(leftBorder==="" && rightBorder===""){ //если нет ни даты заселения, ни выселения
                            updateActiveDays(i,"")
                        } else if(leftBorder!=="" && rightBorder==="") { //если есть дата начала заселения, но нет даты выселения
                            let tempArr = []
                            if(+leftBorder<i){
                                for(let p=+leftBorder;p<=i;p++){
                                    if(bookedDatesArr.includes(p)){
                                        tempArr.push(p)
                                    }
                                }
                            } else {
                                for(let p=i;p<=+leftBorder;p++){
                                    if(bookedDatesArr.includes(p)){
                                        tempArr.push(p)
                                    }
                                }
                            }

                            if(tempArr.length===0){ // ЕСЛИ В МАССИВЕ НЕТ ЧИСЕЛ, КОТОРЫЕ ЗАБРОНИРОВАЛИ ДРУГИЕ КЛИЕНТЫ
                                if(+leftBorder!==i){ //если дата начала заселения не соответствует дате на которую кликнули

                                    updateActiveDays(
                                        +leftBorder < i ? leftBorder : i,
                                        +leftBorder < i ? i : leftBorder
                                    )
                                } else { //если дата начала заселения соответствует дате на которую кликнули
                                    updateActiveDays("","")
                                }
                            } else {
                                updateActiveDays(
                                    i,
                                    ""
                                )
                            }

                        } else if(leftBorder!==""&& rightBorder!==""){ //если есть и дата начала заселения, и дата выселения
                            let tempArr = [];
                            if(+leftBorder < i){
                                for(let p = +leftBorder;p<=i;p++){
                                    if(bookedDatesArr.includes(p)){
                                        tempArr.push(p)
                                    }
                                }
                            } else {
                                for(let p = i;p <= +leftBorder ; p++){
                                    if(bookedDatesArr.includes(p)){
                                        tempArr.push(p)
                                    }
                                }
                            }
                            if(tempArr.length===0){
                                if(i < +leftBorder || i > +rightBorder){ //дополнительно прописываем условие на расширение диапазона бронирования (кликнули за рамками текущего диапазона)
                                    updateActiveDays(
                                        i < +leftBorder ? i : leftBorder,
                                        i > +rightBorder ? i : rightBorder,
                                    )
                                } else if(i > +leftBorder && i < +rightBorder){ //дополнительно прописываем условие на уменьшение диапазона бронирования (кликнули в пределах рамок текущего диапазона)
                                    const delta = +rightBorder - +leftBorder,
                                        lowDelta = i - +leftBorder,
                                        hightDelta = +rightBorder - i;
                                    if(delta-lowDelta>delta-hightDelta){ //если дата на которую кликнули ближе к левой границе внутри текущего диапазона
                                        updateActiveDays(
                                            i,
                                            rightBorder
                                        )
                                    } else { //если дата на которую кликнули всё-таки ближе к правой границе внутри текущего диапазона
                                        updateActiveDays(
                                            leftBorder,
                                            i
                                        )
                                    }
                                } else if(i === +leftBorder || i === +rightBorder){ //если кликнули на левую/правую крайнюю дату текущего диапазона
                                    if(i === +leftBorder){ //если левая граница
                                        updateActiveDays(
                                            rightBorder,
                                            ""
                                        )
                                    } else { //если правая граница
                                        updateActiveDays(
                                            leftBorder,
                                            ""
                                        )

                                    }
                                }
                            } else {
                                updateActiveDays(
                                    i,
                                    ""
                                )
                            }
                        }

                    }

                }}
                className={`day-number-wrapper${

                    ( i === +leftBorder && rightBorder) ? " colored_left" : (
                        i === +rightBorder ? " colored_right" : (
                            (i > +leftBorder && i < +rightBorder) ? " colored_full" : ""
                        )
                    )
                } ${( i + firstDayIndex - 1) % 7===0 ? " right-rounded" : ((( i + firstDayIndex - 1) === 8 || ( i + firstDayIndex - 1) === 15 || ( i + firstDayIndex - 1) ===22 || (i+firstDayIndex-1)===29) ? "left-rounded" : "")}`
                }>
                <div
                    className={`day-number ${bookedDatesArr?.includes(i) ? "booked-day" :""} ${
                        i===+leftBorder ? " chosen" : (
                            i===+rightBorder ? " chosen" : ""
                        )
                    }`}>{i}</div>
            </div>
        </React.Fragment>
    );
}

export default CalendarSingleDay;