import React, {useEffect} from 'react'
import { motion } from "framer-motion"
import styles from './OurServices.module.scss'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'

const cardAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3}
    } 
}



const OurServices = ({isOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

        return (
                <section 
                className={styles.main} 
                >
                <div className={styles.container}>
                    <BreadCrumbs
                        text={'Предложения и сервис'}
                    />
                    <h2 className={styles.title}>Наши предложения</h2>
                    <ul className={styles.list}>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.03, once: true}}
                            >
                            <div className={styles.overlay}>
                                <div className={styles.box_info}>
                                    <div className={styles.item_box}>
                                        <h3 className={styles.item_title}>Сезонная акция</h3>
                                        <p className={styles.item_action}>60 000 ₽ + ком. услуги</p>
                                        <p className={styles.item_text}>Месячное проживание в Laspi Apartments</p>
                                    </div>
                                    <div className={styles.box_one}>
                                        <button className={styles.item_button} type='button' onClick={isOpen} aria-label='Отправить заявку'>забронировать
                                        </button>
                                        <p className={styles.comment}>*Действует с 1 ноября по 28 апреля</p>
                                    </div>
                                </div>
                            </div>
                        </motion.li>
                        <motion.li 
                            className={styles.item_two}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.03, once: true}}
                            >
                            <div className={styles.overlay}>
                                <div className={styles.box_info}>
                                    <div className={styles.item_box}>
                                        <h3 className={styles.item_title}>Романтическое приветствие</h3>
                                        <p className={styles.item_text}>К Вашему приезду мы украсим номер лепестками роз, уникальным декором, на стол поставим корзину фруктов и бутылку охлажденного вина или шампанского, по Вашему желанию. Стоимость услуги составляет 3 000 руб., при заказе за 72 часа до заезда и полной предоплате.</p>
                                    </div>    
                                    <button className={styles.item_button} type='button' onClick={isOpen} aria-label='Отправить заявку'>заказать</button>
                                </div>
                            </div>
                        </motion.li>
                        <motion.li 
                            className={styles.item_three}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.03, once: true}}
                            >
                            <div className={styles.overlay}>
                                <div className={styles.box_info}>
                                    <div className={styles.item_box}>    
                                        <h3 className={styles.item_title}>Трансфер</h3>
                                        <p className={styles.item_text}>Чтобы Вы всегда прибывали в желаемое место вовремя, Laspi Apartments предоставляет гостям услугу трансфера от/до аэропорта, ж/д вокзала. Воспользоваться данной услугой очень просто: при бронировании уточните о необходимости трансфера от аэропорта до апартаментов или наоборот.Будьте уверены, что Вас встретят в нужное время и доставят быстро и комфортно.</p>
                                    </div>
                                    <p className={styles.comment}>*Стоимость уточняется при бронировании</p>
                                </div>    
                            </div>
                        </motion.li>
                        <motion.li 
                            className={styles.item_four}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.03, once: true}}
                            >
                            <div className={styles.overlay}>
                                <div className={styles.box_info}>
                                    <div className={styles.item_box}>    
                                        <h3 className={styles.item_title}>Фотосессии</h3>
                                        <p className={styles.item_text}>Как долго Вы хотите помнить свой незабываемый отпуск? Крым славится наличием множества красивейших, необычных и захватывающих дух мест. Чтобы полученные впечатления остались с Вами надолго, мы предлагаем нашим гостям услуги профессионального фотографа. Возможны фотосессии как на территории нашего комплекса, так и выездные.</p>
                                    </div>
                                </div>        
                            </div>
                        </motion.li>
                        <motion.li 
                            className={styles.item_five}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.03, once: true}}
                            >
                            <div className={styles.overlay}>
                                <div className={styles.box_info}>
                                    <div className={styles.item_box}>     
                                        <h3 className={styles.item_title}>Отдых с детьми</h3>
                                        <p className={styles.item_text}>Для самых маленьких гостей мы бесплатно предоставляем детские кроватки, постельное белье и стульчики для кормления. Мы стремимся создать все необходимые условия для уютного семейного отдыха.</p>
                                    </div>
                                </div>   
                            </div>
                        </motion.li>
                        <motion.li 
                            className={styles.item_six}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.03, once: true}}
                            >
                            <div className={styles.overlay}>
                                <div className={styles.box_info}>
                                    <div className={styles.item_box}>    
                                        <h3 className={styles.item_title}>Настольные игры</h3>
                                        <p className={styles.item_text}>Laspi Apartments предлагает своим гостям воспользоваться различными настольными играми. В Вашем распоряжении окажется множество популярных настольных игр, которые помогут скрасить досуг и принесут радость и веселье. Это отличный повод весело провести время всей семьей.</p>
                                    </div>
                                </div>       
                            </div>
                        </motion.li>
                    </ul>
                </div>    
                </section>
            
        )
    }
    
    export default OurServices