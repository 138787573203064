import React from 'react'
import { Link } from 'react-router-dom'
import styles from './MenuMobile.module.scss';
import logo from '../../image/Header/logo.svg'

const MenuMobile = ({onClose, isMenuMobile}) => {

    return (
        <div className={`${isMenuMobile === true ? styles.main : styles.main_open}`}>
            <div className={styles.box}>
                <img className={styles.logo} src={logo} alt = 'логотип'/>
                <button className={styles.button} onClick={onClose} aria-label='закрыть меню'/>
            </div>
            <nav className={styles.nav}>
                <Link className={styles.menu_link} to="/" onClick={onClose}>Главная</Link>
                <Link className={styles.menu_link} to="/o-nas" onClick={onClose}>О нас</Link>
                <Link className={styles.menu_link} to="/apartments" onClick={onClose}>Бронирование</Link>
                <Link className={styles.menu_link} to="/nashi-predlozheniya" onClick={onClose}>Наши предложения</Link>
                <Link className={styles.menu_link} to="/kontakty" onClick={onClose}>Контакты</Link>
                </nav>
        </div>
    );
}

export default MenuMobile