import React from 'react'
import { Link } from 'react-router-dom'
import styles from './InitialPage.module.scss'
import video from './laspi-swimming-pool.mp4'
import apartments from '../../image/InitialPage/hotel_icon.svg'
import person from '../../image/InitialPage/person.svg'
import info from '../../image/InitialPage/information.svg'
import logo from '../../image/Header/logo.svg'
import ScreenSize from '../../hooks/ScreenSize'
import poster from '../../image/InitialPage/img.png'


const InitialPage = () => {

    const width = ScreenSize()


    return (
        <section className={styles.main} id='initial'>
            <div className={styles.video_box}>
                <video autoPlay loop muted playsInline poster={poster} className={styles.video}
                >
                    <source src={video} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
            </div>
            <div className={styles.overlay}>
                <div className={styles.box_info}>
                    <img className={styles.logo} src={logo} alt = 'логотип'/>
                    <p className={styles.text}>ДОБРО ПОЖАЛОВАТЬ</p>
                    <h1 className={styles.title}>МЕСТО,&#160;КУДА ХОЧЕТСЯ ВЕРНУТЬСЯ ВНОВЬ</h1>
                    <h1 className={styles.title_min}>МЕСТО,&#160;КУДА ХОЧЕТСЯ ВЕРНУТЬСЯ ВНОВЬ</h1>
                </div>
                <ul className={styles.box_button}>
                    <li className={styles.item}>
                        <Link className={styles.link} to='/o-nas'>
                            <img className={styles.item_image} src={info} alt = 'логотип'/>
                            <p className={styles.item_text}>О нас</p>
                        </Link>
                    </li>
                    <li className={styles.item}>
                        <Link className={styles.link} to='/apartments'>
                            <div className={styles.item_box}>
                                <img className={styles.item_image} src={apartments} alt = 'логотип'/>
                                <p className={styles.item_text}>Бронирование</p>
                            </div>
                            <span className={styles.pulse}></span>
                            <span className={styles.pulse_two}></span>
                        </Link>
                    </li>
                    <li className={styles.item}>
                        <Link className={styles.link} to='/nashi-predlozheniya'>
                            <img className={styles.item_image} src={person} alt = 'логотип'/>
                            <p className={styles.item_text}>Наши предложения</p>
                        </Link>    
                    </li>
                </ul>
            </div>
        </section>
    )
}

export default InitialPage

/*{width >= 500 ? (<video autoPlay loop muted playsInline className={styles.video}
    >
        <source src={video} type="video/mp4"/>
        Your browser does not support the video tag.
    </video>) : (<div className={styles.image}> </div>)}*/