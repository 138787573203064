import React from 'react'
import styles from './ServiceIcons.module.scss'
import parking from '../../image/Rooms/Icons/parking.svg'
import bed from '../../image/Rooms/Icons/кровать.svg'
import wc from '../../image/Rooms/Icons/wc.svg'
import shower from '../../image/Rooms/Icons/shower.svg'
import television from '../../image/Rooms/Icons/television.svg'
import refrigerator from '../../image/Rooms/Icons/refrigerator.svg'
import conditioner from '../../image/Rooms/Icons/conditioner.svg'
import table from '../../image/Rooms/Icons/table.svg'
import sofa from '../../image/Rooms/Icons/sofa.svg'
import wifi from '../../image/Rooms/Icons/wifi.svg'
import safebox from '../../image/Rooms/Icons/safebox.svg'
import machine from '../../image/Rooms/Icons/machine.svg'
import hairdryer from '../../image/Rooms/Icons/hairdryer.svg'
import vacuum from '../../image/Rooms/Icons/vacuum.svg'
import iсon from '../../image/Rooms/Icons/утюг.svg'
import hanger from '../../image/Rooms/Icons/hanger.svg'
import microwave from '../../image/Rooms/Icons/microwave.svg'
import stove from '../../image/Rooms/Icons/stove.svg'
import cook from '../../image/Rooms/Icons/cook.svg'
import kitchen from '../../image/Rooms/Icons/kitchen.svg'
import towel from '../../image/Rooms/Icons/towel.svg'
import icon from '../../image/Rooms/Icons/стул.svg'
import dishwasher from '../../image/Rooms/Icons/dishwasher.svg'

const ServiceIconsPlus = () => {

        return (
            <ul className={styles.icon_list}>
            <li className={styles.icon_item}>
                <img className={styles.icon_image} src={parking} alt = 'иконка'/>
                <p className={styles.icon_text}>бесплатная парковка</p>
            </li>
            <li className={styles.icon_item}>
                <img className={styles.icon_image} src={wifi} alt = 'иконка'/>
                <p className={styles.icon_text}>wi-fi</p>
            </li>
            <li className={styles.icon_item}>
                <img className={styles.icon_image} src={wc} alt = 'иконка'/>
                <p className={styles.icon_text}>туалет</p>
            </li>
            <li className={styles.icon_item}>
                <img className={styles.icon_image} src={shower} alt = 'иконка'/>
                <p className={styles.icon_text}>душ</p>
            </li>
            <li className={styles.icon_item}>
                <img className={styles.icon_image} src={hanger} alt = 'иконка'/>
                <p className={styles.icon_text}>гардеробная</p>
            </li>
            <li className={styles.icon_item}>
                <img className={styles.icon_image} src={conditioner} alt = 'иконка'/>
                <p className={styles.icon_text}>кондиционер</p>
            </li>
            <li className={styles.icon_item}>
                <img className={styles.icon_image} src={television} alt = 'иконка'/>
                <p className={styles.icon_text}>телевизор</p>
            </li>
            <li className={styles.icon_item}>
                <img className={styles.icon_image} src={bed} alt = 'иконка'/>
                <p className={styles.icon_text}>двуспальная кровать</p>
            </li>
            <li className={styles.icon_item}>
                <img className={styles.icon_image} src={sofa} alt = 'иконка'/>
                <p className={styles.icon_text}>раскладной диван</p>
            </li>
            <li className={styles.icon_item}>
                <img className={styles.icon_image} src={table} alt = 'иконка'/>
                <p className={styles.icon_text}>стол и стулья</p>
            </li>
            <li className={styles.icon_item}>
                <img className={styles.icon_image} src={refrigerator} alt = 'иконка'/>
                <p className={styles.icon_text}>холодильник</p>
            </li>
            <li className={styles.icon_item}>
                <img className={styles.icon_image} src={safebox} alt = 'иконка'/>
                <p className={styles.icon_text}>сейф</p>
            </li>
            <li className={styles.icon_item}>
                <img className={styles.icon_image} src={machine} alt = 'иконка'/>
                <p className={styles.icon_text}>стиральная машина</p>
            </li>
            <li className={styles.icon_item}>
                <img className={styles.icon_image} src={hairdryer} alt = 'иконка'/>
                <p className={styles.icon_text}>фен</p>
            </li>
            <li className={styles.icon_item}>
                <img className={styles.icon_image} src={vacuum} alt = 'иконка'/>
                <p className={styles.icon_text}>пылесос</p>
            </li>
            <li className={styles.icon_item}>
                <img className={styles.icon_image} src={iсon} alt = 'иконка'/>
                <p className={styles.icon_text}>утюг и доска</p>
            </li>
            <li className={styles.icon_item}>
                <img className={styles.icon_image} src={stove} alt = 'иконка'/>
                <p className={styles.icon_text}>электроплита</p>
            </li>
            <li className={styles.icon_item}>
                <img className={styles.icon_image} src={cook} alt = 'иконка'/>
                <p className={styles.icon_text}>духовой шкаф</p>
            </li>
            <li className={styles.icon_item}>
                <img className={styles.icon_image} src={microwave} alt = 'иконка'/>
                <p className={styles.icon_text}>микроволновка</p>
            </li>
            <li className={styles.icon_item}>
                <img className={styles.icon_image} src={kitchen} alt = 'иконка'/>
                <p className={styles.icon_text}>посуда и приборы</p>
            </li>
            <li className={styles.icon_item}>
                <img className={styles.icon_image} src={towel} alt = 'иконка'/>
                <p className={styles.icon_text}>белье и полотенца</p>
            </li>
            <li className={styles.icon_item}>
                <img className={styles.icon_image} src={icon} alt = 'иконка'/>
                <p className={styles.icon_text}>балконная мебель</p>
            </li>
            <li className={styles.icon_item}>
                <img className={styles.icon_image} src={dishwasher} alt = 'иконка'/>
                <p className={styles.icon_text}>посудомоечная машина</p>
            </li>
        </ul>                        
        )
    }
    
    export default ServiceIconsPlus