import React, {useEffect} from 'react'
import styles from './Main.module.scss'
import InitialPage from '../../components/initialPage/InitialPage'

const Main = ({isOpen, popupOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <section className={styles.main}>
            <InitialPage
                isOpen={isOpen}
            />  
        </section>
    )
}

export default Main