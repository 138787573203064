import {configureStore} from "@reduxjs/toolkit";
import calendar from "../components/Calendar/calendar.slice";

const store = configureStore({
    reducer: {
        calendar,
    },
    devTools: true
})

export default store