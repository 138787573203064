import React from 'react';
import styles from './CalendarArrowItem.module.scss'
import {
    setChosenDate,
    setChosenMonth,
    setChosenYear
} from '../../Calendar/calendar.slice'
import {batch, useDispatch} from "react-redux";
import {useTypedSelector} from "../../../hooks/useTypedSelector";

const CalendarArrowItem = ({argument, arrowClass}) => {
    const {chosenMonthFullDate, chosenMonth, chosenYear} = useTypedSelector(state => state.calendar);
    const obj = argument === "inc" ? {
        param: 1,
        borderMonthCond: 11,
        borderMonthValue: 0
    } : {
        param: -1,
        borderMonthCond: 0,
        borderMonthValue: 11
    };
    const dispatch = useDispatch();
    const currentDate = new Date();
    const parsedDate = new Date(JSON.parse(chosenMonthFullDate));
    const editedChosenDate = parsedDate.setMonth(parsedDate.getMonth() + obj.param);
    const monthDelta = Math.floor(editedChosenDate - currentDate.setMonth(currentDate.getMonth() - 1));


    return (
        <button 
        className={arrowClass} 
        type='button' 
        aria-label='Сменить дату'
        onClick={(e)=>{
            if(monthDelta > 0) {
                e.preventDefault();
                dispatch(setChosenDate(editedChosenDate));
                if(chosenMonth !== obj.borderMonthCond){
                    dispatch(setChosenMonth(chosenMonth + obj.param));
                } else {
                    batch(()=>{
                        dispatch(setChosenMonth(obj.borderMonthValue));
                        dispatch(setChosenYear(chosenYear + obj.param));
                    })
                }
            }
        }}
        />
    );
};

export default CalendarArrowItem