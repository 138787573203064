import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'
import { motion } from "framer-motion"
import styles from './Apartments.module.scss'
import { v4 as uuidv4 } from 'uuid'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import arrow from '../../image/Apartments/arrow.svg'
import place from '../../image/Apartments/plo.svg'
import person from '../../image/Apartments/person.svg'
import icon from '../../image/Apartments/icon.svg'
import { arrApartments } from '../../utils/data'

const cardAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3}
    } 
}



const Apartments = ({popupOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

        return (
            <section 
                className={styles.main} 
            >
                <div className={styles.container}> 
                <BreadCrumbs
                    text={'Апартаменты'}
                />
                
                    <h2 className={styles.title}>Апартаменты</h2>
                    <ul className={styles.list}>
                        
                        {arrApartments.map((item, index) => {
                        const keyUid = uuidv4()
                            return(
                                <motion.li
                                    key={keyUid}
                                    className={styles.item} 
                                    variants={cardAnimation}
                                    initial='hidden'
                                    whileInView='visible'
                                    viewport={{amount: 0.03, once: true}}
                                >
                                    <Link className={styles.link} to={item.link}>
                                    <div className={styles.cell_box}>
                                        <img className={styles.cell_image} src={item.image} alt='баня'/>
                                    </div>
                                    <div className={styles.cell_info}>
                                        <h3 className={styles.cell_subtitle}>{item.name}</h3>
                                        <div className={styles.box_icon}>
                                            <div className={styles.item_icon}>
                                                <img className={styles.icon} src={place} alt='стрелочка'/>
                                                <p className={styles.text_icon}>{item.metre}</p>
                                            </div>
                                            <div className={styles.item_icon}>
                                                <img className={styles.icon} src={person} alt='стрелочка'/>
                                                <p className={styles.text_icon}>{item.people}</p>
                                            </div>
                                            <div className={styles.item_icon}>
                                                <img className={styles.icon} src={icon} alt='стрелочка'/>
                                                <p className={styles.text_icon}>{item.level}</p>
                                            </div>
                                        </div>
                                        <p className={styles.cell_number}>{item.price} ₽</p>
                                    </div>
                                    <p className={styles.cell_text}>Забронировать</p>
                                    <span className={styles.cell_arrow}>
                                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                                    </span>
                                    </Link>    
                                </motion.li>
                            ) 
                    })}     
                    </ul>
                </div>
            </section>
        )
    }
    
    export default Apartments