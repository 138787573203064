import React from 'react';
import styles from './WeekDays.module.scss'

const WeekDays = () => {
    return (
        <div className={styles.week_days}>
            <div key="week-day-1" className={styles.week_day}>пн</div>
            <div key="week-day-2" className={styles.week_day}>вт</div>
            <div key="week-day-3" className={styles.week_day}>ср</div>
            <div key="week-day-4" className={styles.week_day}>чт</div>
            <div key="week-day-5" className={styles.week_day}>пт</div>
            <div key="week-day-6" className={styles.week_day}>сб</div>
            <div key="week-day-7" className={styles.week_day}>вс</div>
        </div>
    );
};

export default WeekDays;