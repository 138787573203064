import React, { useState, useCallback, useEffect} from 'react'
import { Route, Routes, useLocation} from 'react-router-dom'
import app from './App.module.scss'
import AppHeader from '../AppHeader/AppHeader'
import Main  from '../../pages/Main/Main'
import ModalVideo from '../ModalVideo/ModalVideo'
import { ModalForm } from '../ModalForm/ModalForm'
import MenuMobile from '../MenuMobile/MenuMobile'
import PageNotFound from '../../pages/PageNotFound/PageNotFound'
import Contacts  from '../../pages/Contacts/Contacts'
import Modal from '../Modal/Modal'
import ModalImage from '../ModalImage/ModalImage'
import Footer from '../Footer/Footer'
import OurServices from '../../pages/OurServices/OurServices'
import Apartments from '../../pages/Apartments/Apartments'
import AboutUs from '../../pages/AboutUs/AboutUs'
import TwentySeven from '../../pages/TwentySeven/TwentySeven'
import NineTeen from '../../pages/NineTeen/NineTeen'
import FifTeen from '../../pages/FifTeen/FifTeen'
import ThirtyFour from '../../pages/ThirtyFour/ThirtyFour'
import Fifty from '../../pages/Fifty/Fifty'
import FortyFour from '../../pages/FortyFour/FortyFour'
import FortyEight from '../../pages/FortyEight/FortyEight'
import Thirteen from '../../pages/Thirteen/Thirteen'
import Twenty from '../../pages/Twenty/Twenty'


export const App = () =>{

  const routes  = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [isMenuMobile, setIsMenuMobile] = useState(false)
  const [isPopupFormOpen, setIsPopupFormOpen] = useState(false)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [isPopupImageOpen, setIsPopupImageOpen] = useState(false)
  const [isPopupVideoOpen, setIsPopupVideoOpen] = useState(false)
  const [isArr, setIsArr] = useState(null)
  const [isVideo, setIsVideo] = useState(null)

  const handleCloseMenuMobile = useCallback(() => {
    setIsMenuMobile(false)
  }, []);

  const handleOpenMenuMobile = useCallback(() => {
    setIsMenuMobile(true)
  }, []);

  const handleFormOpen = useCallback(() => {
    setIsPopupFormOpen(true)
  }, []);

  const handleFormClose = useCallback(() => {
    setIsPopupFormOpen(false)
  }, []);

  const handleOpen = useCallback(() => {
    setIsPopupOpen(true)
  }, []);

  const handleClose = useCallback(() => {
    setIsPopupOpen(false)
  }, []);

  const handleImageClose = useCallback(() => {
    setIsPopupImageOpen(false)
  }, []);

  const handleImageOpen = useCallback((arr) => {
      setIsPopupImageOpen(true)
      setIsArr(arr)
  }, [])

  const handleVideoClose = useCallback(() => {
    setIsPopupVideoOpen(false)
  }, []);

  const handleVideoOpen = useCallback((video) => {
      setIsPopupVideoOpen(true)
      setIsVideo(video)
  }, [])


  return (
    <div className={app.page}>
    <AppHeader
      isOpen={handleOpenMenuMobile}
    />
      <Routes> 
        <Route  path='/'  element={
          <Main
            isOpen={handleFormOpen}
            popupOpen={handleOpen}
          />
        }/>
        <Route  path='/o-nas'  element={
          <AboutUs
          />
        }/>
        <Route  path='/nashi-predlozheniya'  element={
          <OurServices
              isOpen={handleFormOpen}
          />
        }/>
        <Route  path='/apartments'  element={
          <Apartments
          />
        }/>
        <Route  path='/kontakty'  element={
          <Contacts
          />
        }/>
        <Route  path='/apartment-27-6'  element={
          <TwentySeven
            isImageOpen={handleImageOpen}
            isOpen={handleFormOpen}
            isVideoOpen={handleVideoOpen}
            popupOpen={handleOpen}
          />
        }/>
        <Route  path='/apartment-19-6'  element={
          <NineTeen
            isImageOpen={handleImageOpen}
            isOpen={handleFormOpen}
            isVideoOpen={handleVideoOpen}
            popupOpen={handleOpen}
          />
        }/>
        <Route  path='/apartment-15-5'  element={
          <FifTeen
            isImageOpen={handleImageOpen}
            isOpen={handleFormOpen}
            isVideoOpen={handleVideoOpen}
            popupOpen={handleOpen}
          />
        }/>
        <Route  path='/apartment-34-5'  element={
          <ThirtyFour
            isImageOpen={handleImageOpen}
            isOpen={handleFormOpen}
            isVideoOpen={handleVideoOpen}
            popupOpen={handleOpen}
          />
        }/>
        <Route  path='/apartment-50-6'  element={
          <Fifty
            isImageOpen={handleImageOpen}
            isOpen={handleFormOpen}
            isVideoOpen={handleVideoOpen}
            popupOpen={handleOpen}
          />
        }/>
        <Route  path='/apartment-44-5'  element={
          <FortyFour
            isImageOpen={handleImageOpen}
            isOpen={handleFormOpen}
            isVideoOpen={handleVideoOpen}
            popupOpen={handleOpen}
          />
        }/>
        <Route  path='/apartment-48-6'  element={
          <FortyEight
            isImageOpen={handleImageOpen}
            isOpen={handleFormOpen}
            isVideoOpen={handleVideoOpen}
            popupOpen={handleOpen}
          />
        }/>
        <Route  path='/apartment-13-6'  element={
          <Thirteen
            isImageOpen={handleImageOpen}
            isOpen={handleFormOpen}
            isVideoOpen={handleVideoOpen}
            popupOpen={handleOpen}
          />
        }/>
        <Route  path='/apartment-20-6'  element={
          <Twenty
            isImageOpen={handleImageOpen}
            isOpen={handleFormOpen}
            isVideoOpen={handleVideoOpen}
            popupOpen={handleOpen}
          />
        }/>
        <Route path='*' element={
          <PageNotFound/>
                  }/>        
      </Routes>
      {routes.pathname !== '/' && <Footer/>}
      <MenuMobile
        isMenuMobile={isMenuMobile}
        onClose={handleCloseMenuMobile}
      />
      {isPopupFormOpen && <ModalForm
        onClose={handleFormClose}
        popupOpen={handleOpen}
      ></ModalForm>}
      {isPopupOpen && 
      <Modal
        onClose={handleClose}
      />}
      {isPopupImageOpen && 
      <ModalImage
        onClose={handleImageClose}
        arr={isArr}
      />}
      {isPopupVideoOpen && 
      <ModalVideo
        onClose={handleVideoClose}
        video={isVideo}
      />}
    </div>
  )
}